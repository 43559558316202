import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { SERVER_URL } from '../../../constants';

export default function teamMember(props) {
    const {
        fadeIn,
        infoClass,
        name,
        position,
        descriptionPar1,
        descriptionPar2,
        descriptionPar3,
        descriptionPar4,
        descriptionPar5,
        imgCode,
        containerClass
    } = props;
    return(
        <Row className={containerClass} data-aos={`fade-${fadeIn}`} data-aos-duration="3000">
            <Col className="image-wraper">
                <img alt="" src={`${SERVER_URL}/images/${imgCode}`}></img>
            </Col>
            <Col>
                <div className={infoClass}>
                    <span>{name}</span>
                    <h2>{position}</h2>
                    <p>{descriptionPar1}</p>
                    <p>{descriptionPar2}</p>
                    <p>{descriptionPar3}</p>
                    <p>{descriptionPar4}</p>
                    <p>{descriptionPar5}</p>
                </div>
            </Col>
        </Row>
    )
}