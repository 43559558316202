import React from 'react';
import { Container, Row } from 'react-bootstrap';
import Logo from '../../../assets/img/logo-blue.png';
import './style.scss';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
      <footer>
          <Container>
              <Row>
                <div className="col-12">
                    <div className="footer-links">
                            <Link to="/" >Home</Link>
                            <Link to="/about" >About</Link>
                            <Link to="/work">Work</Link>
                            <Link to="/team" >Team</Link>
                            <Link to="/careers" >Careers</Link>
                            <Link to="/contact" >Contact</Link>
                    </div>
                </div>
              </Row>
              <Row>
                <div className="col-12">
                    <div className="footer-logo">
                        <img src={Logo} alt="Company A"></img>
                    </div>
                </div>
              </Row>
          </Container>
      </footer>
    )
}
export default Footer;
