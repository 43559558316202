import React from 'react';
import './style.scss';
import YouTubeBackground from '../../atoms/YouTubeBackground';
import { Row } from 'react-bootstrap';

const videoHomeBannerURL ="https://www.wearecompanya.com/static/videos/office.mp4"

const Banner = (props) => (
  <div className="component container-fluid">
    <Row>
      <YouTubeBackground videoURL={videoHomeBannerURL} />
      <div className="home-main-section text-white col-12" data-aos="fade-down" data-aos-duration="2000">
        <div className="heading">
          <p>We are <span className="font-light-blue d-block">Company <span className="font-white">A</span></span></p>
        </div>
        <div className="home-content-section">
          <h3>Custom Development | System Integration | Application Support and Maintenance.</h3>
        </div>
      </div>
    </Row>
  </div>
)

export default Banner;
