import React from 'react'

import Header from '../components/atoms/Header';
import './style.scss';
import Footer from './../components/atoms/Footer/index';

const Layout = (props) => {
  return (
    <div>
      <Header pathname={props.location.pathname} />
      <div className="layout-content">
        {props.children}
      </div>
      <Footer/>
    </div>
  )
}

export default Layout;