import React from 'react';
import './style.scss';

const CaseBanner = () => {
    return (
        <div className="case-banner container-fluid">
            <div className="case-banner-content">
                <h1>This Is How We Do It</h1>            
            </div>
        </div>
    )
}

export default CaseBanner
