import React, { useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';

import ContactForm from '../../atoms/ContactForm';
import OfficesGroup from '../../atoms/OfficesGroup';

import { postEmail } from './actions'

import './style.scss';

const ContactContent = props => {
  const [status, setStatus] = useState('initial');
  const [messageSent, setMessageSent] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const handleSubmit = (data) => {
    postEmail(data)
      .then(response => {
        const { status } = response.data;
        setStatus(status)
        setMessageError(false)
        setMessageSent(true)
        setStatus('initial')
      })
      .catch(error => {
        setStatus('error');
        setMessageSent(false)
        setMessageError(true)
      })
  }
  return (
    <div className='contact-content font-white'>
      <Container>
        <Row className="contact-row">
          <Col xs={12}>
            <h1>Get in touch</h1>
            <p className="located-paragraph">We're located in California, Texas and Serbia but love to work with clients all over the world. Write us to find out if we're the right partner for your business.</p>
          </Col>
          <Col className="contact-col" lg={6} md={6} sm={12} xs={12}>
            <OfficesGroup
              offices={[
                {
                  name: 'Orange County Office (HQ)',
                  address: "2121 Alton Pkwy Ste 260, Irvine, CA 92606",
                  email: "mgmt@wearecompanya.com"
                },
                {
                  name: "Dallas Office",
                  address: "5345 Town Square Drive, Suite #230, Plano, Texas 75024"
                },
                {
                  name: "Novi Sad Office",
                  address: "Narodnog fronta 21c, 21000 Novi Sad"
                }
              ]}
            />
          </Col>
          <Col className="col" lg={6} md={6} sm={12} xs={12}>
            <ContactForm 
              handleSubmit={handleSubmit}
              status={status}
              messageSent={messageSent}
              messageError={messageError}
            />
          </Col>
          <Col lg={12} className="contact-info">
            <p>New Business & RFP Submissions 949-538-2262 <br/>mgmt@wearecompanya.com</p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ContactContent;