import React from 'react';

import './style.scss'

export default function OfficesGroup(props) {
  const { offices } = props
  return(
    <div className="offices-group">
      <div>
        {
          offices.map(office => (
            <div key={office.name}>
              <strong>{office.name}:</strong>
              <p>{office.address}<br/> {office.email != null ? (<b>info@: {office.email}</b>) : null}</p>
            </div>
          ))
        }
      </div>
    </div>
  )
}