import React from 'react';
import './style.scss';

const WeLikeToKeepBanner = () => {
    return (
        <div className="container we-like-to-keep-banner-container">
            <p className="we-like-to-keep-heading">The Company We Like to Keep</p>
            <p>Our clients are our lifeblood. We work incredibly hard to make sure their working day is easier and not harder. Unline many client/agency/partner relationships our clients enjoy meeting with us.</p>
        </div>
    )
}

export default WeLikeToKeepBanner
