import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Logo from '../../../assets/img/logo-orange.png';
import './style.scss';
import menubar from "../../../assets/img/menubar.png"
import closebtn from "../../../assets/img/closebtn.png"

const Header = (props) => {
    const { pathname } = props;
    const [error, setError] = useState(false)
    useEffect(() => {
        switch(pathname) {
            case '/':
            case '/about':
            case '/work':
            case '/team':
            case '/careers':
            case '/contact':
            case '/we-like-to-keep':
                break;
            default:
                setError(true)
        }
    }, [pathname])
    return (
        <div className={`header ${error && 'error-header-color'}`}>
            <Container fluid id="myHeader">
                <Row>
                    <Col>
                        <div className="site-branding">
                            <Link to="/">
                                <img src={Logo} alt="Company A"></img>
                            </Link>
                        </div>
                        <img src={menubar} className="toggle-btn" onClick={openNav} alt="We are Company A"/>
                    </Col>
                </Row>
            </Container>
            <div id="mySidenav" className="sidenav">
                <div className="close-btn">
                    <img src={closebtn} className="toggle-btn" onClick={closeNav} alt="Menu"/>
                </div>
                <div className="link-container">
                    <Link to="/" onClick={closeNav}>Home</Link>
                    <Link to="/about" onClick={closeNav}>About</Link>
                    <Link to="/work" onClick={closeNav}>Work</Link>
                    <Link to="/team" onClick={closeNav}>Team</Link>
                    <Link to="/careers" onClick={closeNav}>Careers</Link>
                    <Link to="/contact" onClick={closeNav}>Contact</Link>
                </div>
            </div>
        </div>
    )
}
function openNav() {
    let sideNav = document.getElementById("mySidenav");
    // document.getElementById("mySidenav").style.width = "100%";
    sideNav.classList.add("openNav");
    sideNav.classList.remove('closeNav');
}

function closeNav() {
    let sideNav = document.getElementById("mySidenav");
    // document.getElementById("mySidenav").style.width = "0";
    sideNav.classList.add('closeNav');
    sideNav.classList.remove("openNav");  
}

export default Header;