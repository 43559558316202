import React from 'react';
import CaseBanner from '../../components/widgets/CaseBanner';
import Section from '../../components/atoms/Section';
import './style.scss';
import toyotaImgDesktop1 from '../../assets/img/toyota-img.png';
import toyotaImgDesktop2 from '../../assets/img/toyota-img2.png';
import toyotaImgMobile from '../../assets/img/mobile-toyota.png';
import lexusImg from '../../assets/img/lexus.png';

const sections = [
    {
        heading: "Offers Admin Tool Toyota & Lexus",
        subheading: "Situation",
        p1: "Significant budget spend of incentives managed by emailing complex spreadsheets back and forth.",
        p2: "Fraught with manual errors resulting in incorrect data being syndicated to consumer facing sites, ads and shopping tools.",
        p3: "Lengthy administrative and review processes led to 1000's of wasted man hours annually.",
        p4: "Over a period of 18 months we solved a problem that Toyota had been unable to find a solution for - for over 8 years.",
        src: toyotaImgDesktop1,
        sectionHeading: "case-section-heading font-weight-bold",
        fontSubHeading: "color-yellow case-subheading-section font-weight-bold",
        sectionClass: "container-fluid case-section-container",
        textSectionClass: "section-text-wrapper text-left",
        imgClass: "img-case-desktop-1"
    },
    {
        sectionClass: "container-fluid case-section-container",
        sectionHeading: "case-section-heading font-weight-bold",
        fontSubHeading: "color-yellow case-subheading-section font-weight-bold",
        textSectionClass: "section-text-wrapper text-left",
        subheading: "Results",
        number1: "154",
        p1: "documents eliminated",
        number2: "300.000",
        p2: "keystrokes saved",
        number3: "11MM",
        p3: "Toyota digital properties",
        number4: "90%",
        p4: "average time saving",
        p5: "Steps eliminated in the incentive process, improved transparency & accuracy",
        src: lexusImg,
        imgClass: "img-lexus"
    },
    {
        heading: "BuyAToyota.com",
        subheading: "Situation",
        p1: "Due to poor support. Toyota Dealers didn't have trust or confidence in the incumbent agency.",
        p2: "BuyAToyota.com averages 5M visits per month",
        p3: "There were major problems related to site traffic, architecture, speed and overall performance.",
        fontSubHeading: "color-yellow case-subheading-section font-weight-bold",
        sectionClass: "container-fluid case-section-container",
        sectionHeading: "case-section-heading font-weight-bold",
        src: toyotaImgDesktop2,
        textSectionClass: "section-text-wrapper text-left",
        imgClass: "img-case-desktop-2"
    },
    {
        subheading: "Results",
        p1: "increase YOY site traffic",
        p2: "click to dealer conversions",
        p3: "(432k) increase in leads generated",
        p4: "time on site increase",
        p5: "page views increase",
        p6: "different site improvements made to the site over 96 bi-weekly sprints",
        fontSubHeading: "color-yellow case-subheading-section font-weight-bold",
        number1: "74%",
        number2: "119%",
        number3: "37%",
        number4: "108%",
        number5: "97%",
        number6: "1300",
        sectionHeading: "case-section-heading",
        src: toyotaImgMobile,
        sectionClass: "container-fluid case-section-container",
        textSectionClass: "section-text-wrapper text-left",
        imgClass: "img-case-mobile"
    },

];

const Case = () => {
    return (
        <div>
            <CaseBanner />
            <Section {...sections[0]} />
            <Section {...sections[1]} />
            <Section {...sections[2]} />
            <Section {...sections[3]} />
        </div>
    )
}

export default Case;
