const initialState = {
  positions: {
    status: 'initial',
    positions: []
  },
  employes: {
    status: 'initial',
    employes: []
  }
};

const types = {
  GET_POSITIONS: 'GET_POSITIONS',
  GET_EMPLOYES: 'GET_EMPLOYES'
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_POSITIONS:
      return {
        ...state,
        positions: {
          ...state.positions,
          ...action.payload
        }
      }
    case types.GET_EMPLOYES:
      return {
        ...state,
        employes: {
          ...state.employes,
          ...action.payload
        }
      }
    default:
      throw new Error("Unexpected action");
  }
};
export { initialState, types, reducer };
