import React from 'react';
import './style.scss';
import TeamBanner from '../../components/widgets/TeamBanner/index';
import Team from './../../components/widgets/Team/index';
import ToyotaLogo from '../../assets/img/Toyota-logo.png';
import LexusLogo from '../../assets/img/Lexus-logo.png';
import Looplabs from '../../assets/img/Looplabs-logo.png';
import HyundaiLogo from '../../assets/img/Hyundai-logo.png';
import KiaLogo from '../../assets/img/Kia-logo.png';
import WordBrownLogo from '../../assets/img/Word&Brown-logo.png';
import FiveAndDoneLogo from '../../assets/img/Five&Done-logo.png';
import CognizantLogo from '../../assets/img/Cognizant-logo.png';
import WeLikeToKeepBanner from '../../components/widgets/WeLikeToKeepBanner';
import WeLikeToKeepContent from '../../components/widgets/WeLikeToKeepContent';

const ourTeam = (props) => {
  const clients = [
    {
        imgSrc: ToyotaLogo,
    },
    {
        imgSrc: LexusLogo,
    },
    {
        imgSrc: Looplabs,
    },
    {
        imgSrc: HyundaiLogo,
    },
    {
        imgSrc: KiaLogo,
    },
    {
        imgSrc: WordBrownLogo,
    },
    {
        imgSrc: FiveAndDoneLogo,
    },
    {
        imgSrc: CognizantLogo,
    },
    {
        clientDescription: "Surround yourself with the dreamers and the doers, the believers and thinkers, but most of all, surround yourself with those who want to achieve greatness together."
    }
  ];

  return (
    <div>
      <TeamBanner />
      <Team/>
      <div className="we-like-to-keep-container font-white">
            <WeLikeToKeepBanner />
            <div className="container">
                <div className="row we-like-to-keep-content-wrapper">
                    {clients.map((client, index) => (
                        <WeLikeToKeepContent key={index} imgSrc={client.imgSrc} clientDescription={client.clientDescription}  />
                    ))
                    }
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default ourTeam;
