import React, { useContext, useEffect } from 'react'
import { StoreContext } from "../../../context/StoreContext";
import PositionList from './PositionList';

import './style.scss'

const OpenPositions = (props) => {
  const { state, actions } = useContext(StoreContext);
  const { positions } = state;
  const getPositions = actions['positions'];

  useEffect(() => {
    getPositions.getPositions();
  }, [])
  return (
    <div id="hiringthing-jobs"></div>
    // <div className="open-positions">
    //   {positions.status === 'error' && <i className="error-message">{positions.error.message}</i>}
    //   {positions.positions && <PositionList positions={positions.positions} /> }
    //   {positions.status === 'pending' && <i>Loading</i> }
    // </div>
    
  )
}

export default OpenPositions;