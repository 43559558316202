import React from 'react';
import Service from '../../atoms/Service';
import './style.scss';
import { Row, Col } from 'react-bootstrap';

const BuisinessStrategy = {
    title: "Business strategy",
    content: "",
    icon: "chart-bar",
    backgroundClass: "bg-color-blue-dark"
}

const ProcessImprovements = {
    title: "Process improvements",
    content: "",
    icon: "random",
    backgroundClass: "bg-color-blue-light"
}

const ProjectMenagement = {
    title: "Project management",
    content: "",
    icon: "stream",
    backgroundClass: "bg-color-yellow"
}

const BusinessAnalysis = {
    title: "Business analysis",
    content: "",
    icon: "search",
    backgroundClass: "bg-color-red"
}

const serviceBox = {
        titleClass: "consulting-services-box-title",
        contentClass: "consulting-services-box-content",
        hl: "hl",
        fontAweAnimation: "fade-right",
        fontAweDuration: "2000",
        textAnimation: "fade-up",
        textDuration: "2000",
        cardWrapperClass: 'consulting-services-box-wrapper',
        cardClass: 'consulting-services-box'
};

const ServiceBox = (props) => (
    <Col style={{padding: 0}} lg={3} md={6} sm={12}>
        <Service {...serviceBox} {...props} />
    </Col>
);

const Services = (props) => (
    <div className="services-container text-white">
        <div className="container-fluid">
            <Row>
                <div className="consulting-services-heading col-12">
                    <h1>As Your Agile Partner We Also Offer
                    <span>Consulting Services</span></h1>
                </div>
            </Row>
            <Row>
                    <ServiceBox {...BuisinessStrategy} />
                    <ServiceBox {...ProcessImprovements} />
                    <ServiceBox {...ProjectMenagement} />
                    <ServiceBox {...BusinessAnalysis} />
            </Row>
        </div>
    </div>
)

export default Services;
