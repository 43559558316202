import React from 'react';
import Card from "../../atoms/Card";
import { Container, Row, Col } from 'react-bootstrap';
import './style.scss';

const CustomDevelopment = {
  title: "Custom Development",
  content: "Our dynamic and quality-driven team rolls out software development solutions in line with the hottest industry trends.",
  icon: "code",
  iconColor: "color-blue-light"
}

const SystemIntegration = {
  title: "System Integration",
  content: "Our collaborative, agile and adaptive software solutions, ensures delivery of predictable results which translates into cost savings.",
  icon: "desktop",
  iconColor: "color-yellow"
}

const AppSupport = {
  title: "Application Support and Maintenance",
  content: "We offer application maintenance and support for your legacy applications, blending maintenance with change management to allow your company to maintain its brand while still sitting on the cutting edge of today’s technology.",
  icon: "cog",
  iconColor: "color-red"
}

const appDevelopment = {
  titleClass: "app-development-title text-left",
  contentClass: "app-development-content",
  hl: "hl-dev",
  cardClass: "app-dev-box",
}

const CustomAppDevelopment = {
  title: "Custom Application Development",
  content: "To our clients, we are business partners building innovative products.  We differentiate ourselves by our personal touches, attention to detail, and an ability to think outside the box. Unlike our larger competitors you can always put a face to a name for any of our team members. We believe in order to create the best solutions we must be one team working towards the same goal.",
  cardClass: "app-dev-box1",
  titleClass: "app-dev-left-title",
  contentClass: "app-dev-left-content",
}

const AppCard = (props) => (
      <Card {...appDevelopment} {...props} />
)

const ApplicationDevelopment = (props) => (
  <div className="app-dev-container">
    <Container className="app-development-cards" >
      <Row>
        <Col xs={12} className="aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
          <div>
            <Card {...appDevelopment} {...CustomAppDevelopment} />
          </div>
        </Col>
        <Col xs={12} className="text-left aos-init aos-animate" data-aos="fade-left" data-aos-duration="1000">
          <Row>
            <Col xs={12} md={4}><AppCard {...CustomDevelopment} /></Col>
            <Col xs={12} md={4}><AppCard {...SystemIntegration} /></Col>
            <Col xs={12} md={4}><AppCard {...AppSupport} /></Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </div>
)

export default ApplicationDevelopment;

