import React from 'react';
import './style.scss';
import { Row } from 'react-bootstrap';


const careersBanner = (props) => (
    <div className="careers-banner-content container-fluid">
        <Row>
            <div className="careers-banner-container font-white col-12">
                <div data-aos="fade-up" data-aos-duration="2000">
                    <h1>Work at Company A</h1>
                    <p>We are made from the best and want the best to work on our team. We’re a small nimble company working with some of the best brands in the world. Join our team to work on the ground floor solutioning with the most innovative technologies and teams to deliver the most amazing products.</p>
                </div>
            </div>
        </Row>
    </div>
)

export default careersBanner;
