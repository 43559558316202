import React from 'react'
import './style.scss'


export default function YouTubeBackground(props){
  return (
    <>
        <video className="video-container" autoPlay loop muted>
          <source src={props.videoURL} type="video/mp4" />
        </video>
    </>
  )
}