import { types } from "../reducers";
import { SERVER_URL } from '../../constants';
import axios from 'axios';

const GET_POSITIONS_URL = `${SERVER_URL}/positions`

export const useActions = (state, dispatch) => {

  const reset = () => ({ 
    type: types.GET_POSITIONS,
    payload: {
      status: 'initial' 
    }
  })

  const begin = () => ({ 
    type: types.GET_POSITIONS,
    payload: {
      status: 'pending'
    }
  })

  const success = (positions) => ({
    type: types.GET_POSITIONS,
    payload: {
      status: 'success',
      positions
    }
  })

  const fail = (error) => ({
    type: types.GET_POSITIONS,
    payload: {
      status: 'error',
      error
    }
  })

  function getPositions() {
    dispatch(begin())

    axios.get(GET_POSITIONS_URL)
    .then(response => {
      dispatch(success(response.data.positions))
    })
    .catch(error => {
      dispatch(fail(error))
    })
  }

  return {
    reset,
    begin,
    success,
    fail,
    getPositions
  };
};
