import React from 'react';
import { Row, Col } from 'react-bootstrap';

const index = (props) => {
    return (
        <div className={props.sectionClass}>
            <div className="container case-container-width">
                <Row className="reverse-row-direction">
                    <Col xs={12} lg={6}>
                        <div className={props.textSectionClass}>
                            <p className={props.sectionHeading}>{props.heading}</p>
                            <p className={props.fontSubHeading}>{props.subheading}</p>
                            <p><span className="color-yellow font-weight-bold">{props.number1}</span> {props.p1}</p>
                            <p><span className="color-yellow font-weight-bold">{props.number2}</span> {props.p2}</p>
                            <p><span className="color-yellow font-weight-bold">{props.number3}</span> {props.p3}</p>
                            <p><span className="color-yellow font-weight-bold">{props.number4}</span> {props.p4}</p>
                            <p><span className="color-yellow font-weight-bold">{props.number5}</span> {props.p5}</p>
                            <p><span className="color-yellow font-weight-bold">{props.number6}</span> {props.p6}</p>
                        </div>
                    </Col>
                    <Col xs={12} lg={6}>
                        <img src={props.src} className={props.imgClass} alt="" />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default index
