import React, { useEffect } from 'react';
import './App.scss';
import Layout from './layout';
import { StoreProvider } from './context/StoreContext';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faStream, faChartBar, faSearch, faRandom, faDesktop, faCog, faSyncAlt, faCode, faCheck, faPlay, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
library.add(faStream, faChartBar, faSearch, faRandom, faDesktop, faCog, faSyncAlt, faCode, faCheck, faPlay, faBars, faTimes);

function App() {
  useEffect(() => {
    window.addEventListener('scroll', scrollFixedHeader)
    return () => {
      window.removeEventListener('scrool', scrollFixedHeader)
    };
  }, [])
  return (
    <StoreProvider>
      <div className="App">
        <Layout />
      </div>
    </StoreProvider>
  );
}

function scrollFixedHeader(event) {
  let header = document.getElementById("myHeader");
  let dissapeared = header.offsetTop +200;
  let sticky = header.offsetTop + 400;

  if (window.pageYOffset > dissapeared) {
    header.classList.add("dissapeared");
    header.classList.remove("sticky");
  } else {
    header.classList.remove("dissapeared");
  }

  if (window.pageYOffset > sticky) {
    header.classList.add("sticky");
    header.classList.remove("dissapeared");
  } else {
    header.classList.remove("sticky");
  }
}
export default App;
