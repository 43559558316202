import React from 'react';
import './style.scss';
import { Container, Col, Row } from 'react-bootstrap';

let AboutBannerText = {
    title: "We are Company A",
    content: `We are Company A because there is no room for B players on our team.${'\u00A0'}What does A stand for?${'\u00A0'}How about Amazing people, Accelerated project launches, Adaptable and Agile methodology.`
};

let aboutText = {
    title: "What We Do",
    par1: {
        title: "Enterprise Solutions",
        text: "We create world class experiences that transform brands and grow businesses. We create best-in-class enterprise solutions, build and maintain applications that create a seamless experience on any device.",
    },
    par2: {
        title: "Digital Business",
        text: "We create products and services that transform organizations for the digital economy.",
    },
    par3: {
        title: "System Integrators",
        text: "We have a passion to build products and applications to drive business results with a cross channel, digital personalization approach, with various SaaS models."
    }
}

const aboutBanner = (props) => (
    <div className="about-banner-container font-white">
        <Container className="about-banner-text-container">
            <Row data-aos="fade-up" data-aos-duration="2000">
                <Col>
                    <h1>{AboutBannerText.title}</h1>
                    <p>{AboutBannerText.content}</p>
                </Col>
            </Row>
            <Row data-aos="fade-up" data-aos-duration="1000">
                <Col className="subheader">
                    <h2><span>{aboutText.title}</span></h2>
                </Col>
            </Row>
            <Row className="about-banner-box-container">
                <Col 
                    className="about-banner-box"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    xs={12}
                    md={4}
                >
                    <h3 className="color-blue-light">{aboutText.par1.title}</h3>
                    <p>{aboutText.par1.text}</p>
                </Col>
                <Col 
                    className="about-banner-box"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    xs={12}
                    md={4}
                >
                    <h3 className="color-yellow">{aboutText.par2.title}</h3>
                    <p>{aboutText.par2.text}</p>
                </Col>
                <Col 
                    className="about-banner-box"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    xs={12}
                    md={4}
                >
                    <h3 className="color-red">{aboutText.par3.title}</h3>
                    <p>{aboutText.par3.text}</p>
                </Col>
            </Row>
        </Container>
    </div>
)

export default aboutBanner;
