import React from 'react';
import InfoPeek from '../../atoms/InfoPeek';
import YouTubeBackground from '../../atoms/YouTubeBackground';
import './style.scss';
import { Row } from 'react-bootstrap';

const infoPeekHome = {
    infoPeekTitle: "We are Company A",
    infoPeekContent: "Take a deeper dive into who we are and where we come from. Get to know Company A and our leadership team.",
    infoPeekAdditionalContent: "We're definitely not the biggest company, but we are one of the best at what we do.",
    infoPeekWrapper: "info-peek-home col-12",
    buttonText: "Get to know us",
    infoPeekTitleClass: "info-peek-home-title",
    infoPeekContentClass: "info-peek-home-content",
    infoPeekAdditionalContentClass: "info-peek-home-add-content",
    link: "/about",
    icon: "desktop",
    fontAweAnimation: "fade-up",
    fontAweDuration: "2000",
    textAnimation: "fade-down",
    textAniDuration: "2000"
};

const peekVideoURL = "https://www.wearecompanya.com/static/videos/waterfall-home-bg.mp4";


const InfoPeekHome = (props) => (
  <div className="info-peek-home-wrapper font-white container-fluid">
    <Row>
      <YouTubeBackground videoURL={peekVideoURL} bgContainerClass="video-container-home-info-peek" video="youtube-iframe-info-home" />
      <InfoPeek {...infoPeekHome}  />
    </Row>
  </div>
)

export default InfoPeekHome;
