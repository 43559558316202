import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import { Router as ReactRouter, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";

import MainLayout from './layout';
import Home from '../pages/Home';
import AboutUs from '../pages/AboutUs';
import Careers from '../pages/Careers';
import ContantUs from '../pages/ContactUs';
import OurTeam from '../pages/OurTeam';
import Case from '../pages/Case';
import NotFound from '../pages/404';


const hist = createBrowserHistory();

const routes = [
    { path: "/", name: "Home", component: Home },
    { path: "/contact", name: "Contact Us", component: ContantUs},
    { path: '/about', name: 'About Us', component: AboutUs },
    { path: '/careers', name: 'Careers', component: Careers },
    { path: '/team', name: 'Our Team', component: OurTeam },
    { path: '/work', name: 'Work', component: Case },
    { path: '/*', name: '404', component: NotFound }
]

const Router = () => {
  useEffect(() => {
      hist.listen(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      })
  }, [])

  return (
    <ReactRouter history={hist} >
        <Switch>
          {routes.map((prop, key) => {
            let Page = prop.component
            return <Route 
              exact
              path={prop.path}
              render={
                (matchProps) => (
                  <MainLayout {...matchProps}>
                    <Helmet>
                      <title>{prop.name === 'Home' ? '': `${prop.name} - `}Company A</title>
                      <link rel="canonical" href={`https://www.wearecompanya.com${prop.path}`} />
                      {prop.name === 'Home' && 
                        <meta 
                          name="description" 
                          content="Company A is a custom application development company that creates products and provides services to transform brands and organizations for the digital world."
                        />
                      }
                    </Helmet>
                    <Page {...matchProps} />
                  </MainLayout>
                )
              } 
              key={key} 
            />;
          })}
        </Switch>
    </ReactRouter>
)}

export default Router;
