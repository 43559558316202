import { types } from "../reducers";
import { SERVER_URL } from '../../constants';
import axios from 'axios';

const GET_EMPLOYES_URL = `${SERVER_URL}/employes`

export const useActions = (state, dispatch) => {

  const reset = () => ({ 
    type: types.GET_EMPLOYES,
    payload: {
      status: 'initial' 
    }
  })

  const begin = () => ({ 
    type: types.GET_EMPLOYES,
    payload: {
      status: 'pending'
    }
  })

  const success = (employes) => ({
    type: types.GET_EMPLOYES,
    payload: {
      status: 'success',
      employes
    }
  })

  const fail = (error) => ({
    type: types.GET_EMPLOYES,
    payload: {
      status: 'error',
      error
    }
  })

  function getEmployes() {
    dispatch(begin())

    axios.get(GET_EMPLOYES_URL)
    .then(response => {
      dispatch(success(response.data.employes))
    })
    .catch(error => {
      dispatch(fail(error))
    })
  }

  return {
    reset,
    begin,
    success,
    fail,
    getEmployes
  };
};
