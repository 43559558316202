import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function Card(props){
  const { 
    cardClass, 
    content, 
    contentClass, 
    fontAweAnimation, 
    fontAweDuration, 
    hl, 
    icon, 
    textAnimation, 
    textDuration,
    title, 
    titleClass,
    backgroundClass,
    iconColor
  } = props
  return (
      <div className={`${cardClass} ${backgroundClass ? backgroundClass:''}`}>
          {icon && <FontAwesomeIcon icon={icon} data-aos={fontAweAnimation} data-aos-duration={fontAweDuration} className={iconColor} /> }
          <h3 className={titleClass} data-aos={textAnimation} data-aos-duration={textDuration}>{title}</h3>
          <div className={hl}></div>
          <p className={contentClass} data-aos={textAnimation} data-aos-duration={textDuration}>{content}</p>
      </div>
  )
}
