import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HoverEffectButton from '../HoverEffectButton';

export default function InfoPeek (props) {
  const { buttonAnimation, buttonAniDuration, textAnimation, textAniDuration, icon, fontAweAnimation, fontAweDuration, infoPeekWrapper, infoPeekTitleClass, infoPeekTitle, infoPeekContentClass, infoPeekContent, infoPeekAdditionalContentClass, infoPeekAdditionalContent, link, buttonText} = props;
    return (
      <div className={infoPeekWrapper}>
        <div data-aos={textAnimation} data-aos-duration={textAniDuration}>
          {icon && <FontAwesomeIcon icon={icon} data-aos={fontAweAnimation} data-aos-duration={fontAweDuration} /> }
          <p className={infoPeekTitleClass}>{infoPeekTitle}</p>
          <p className={infoPeekContentClass}>{infoPeekContent}</p>
          <p className={infoPeekAdditionalContentClass}>{infoPeekAdditionalContent}</p>
        </div>
        <HoverEffectButton href={link} dataAos={buttonAnimation} dataAosDuration={buttonAniDuration}>{buttonText}</HoverEffectButton>
      </div>
    )
}
