import React  from 'react';
import BenefitsList from '../../atoms/BenefitsList';
import './style.scss';
import { Row, Col } from 'react-bootstrap'; 

const CareersSidebar = (props) => (
    <div className="careers-sidebar">
        <Row>
            <Col md={12} lg={12}>
                <p className="careers-sidebar-heading"><span className="font-light-blue">Why</span> Company A?</p>
                <p className="sidebar-text">We are professional, reliable, and innovative business partners who provide personalized solutions with the most talented people. Get the ball rolling and reach out to us. We'd love to chat.</p>
            </Col>
        <Row>

        </Row>
            <Col className="benefits-list-container" md={12} lg={12}>
                <p className="benefits-list-heading">Benefits</p>
                <BenefitsList listClass="benefits-list" />
            </Col>                
        </Row>
    </div>
)

export default CareersSidebar;
