import React from 'react';
import './style.scss';

const HoverEffectButton = (props) => {
    return (
        <div>
        {/* <Button className={props.buttonClass} id="hover-button-effect" href={props.href} variant={props.variant} data-aos={props.dataAos} data-aos-duration={props.dataAosDuration}>
        </Button> */}
        <a id="hover-button-effect" href={props.href} className="btn-uniform btn btn-outline-light" data-aos={props.dataAos} data-aos-duration={props.dataAosDuration}><span className="button-text">{props.children}</span><span className="button-hover-bg"></span>{props.children}</a>
        </div>
    )
}

export default HoverEffectButton;
