import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function index (props) {
    return (
        <div>
            <ul className={props.listClass}>
                <li>
                    <FontAwesomeIcon icon="check" /> Competitive Salaries
                </li>
                <li>
                    <FontAwesomeIcon icon="check" /> Paid Time Off
                </li>
                <li>
                    <FontAwesomeIcon icon="check" /> Full medical, dental and vision insurance
                </li>
                <li>
                    <FontAwesomeIcon icon="check" /> 401K
                </li>
                <li>
                    <FontAwesomeIcon icon="check" /> Bonuses
                </li>
                <li>
                    <FontAwesomeIcon icon="check" /> Company sponsored happy hours
                </li>
            </ul>
        </div>
    )
}
