import React from 'react';
import Banner from '../../components/widgets/Banner';
import Services from '../../components/widgets/Services';
import ApplicationDevelopment from '../../components/widgets/ApplicationDevelopment';
import InfoPeekHome from '../../components/widgets/InfoPeekHome';

const home = (props) => (
  <div>
    <Banner />
    <ApplicationDevelopment />
    <Services />
    <InfoPeekHome />
  </div>
)

export default home;
