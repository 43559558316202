import React from 'react';
import './style.scss';

const WeLikeToKeepContent = ({ imgSrc, clientDescription })=> {
    return (
        <div className="p-3 col-12 col-md-6 col-lg-4">
            <div className="we-like-to-keep-content">
                {imgSrc && <img src={imgSrc} alt="Client logo" /> }
                { clientDescription && <p className="px-3">{clientDescription}</p> }
            </div>
        </div>
    )
}

export default WeLikeToKeepContent
