import React from 'react';
import './style.scss';
import { Row } from 'react-bootstrap';

let aboutText = {
    title: "Agile Consulting and Staff Augmentation",
    par1: "Driven by the dynamic nature of the digital age and faster than ever innovations, Agile transformations are enabling organizations to achieve quicker delivery time to market, address market disruption, focus on customer demands while keeping an eye on profitable growth and employee satisfaction. Company A specializes in providing companies with world class talent to help with the complexity of business.",
    par2: "We realize that your core competency is your business. Managing and training resources is the last thing you need to worry about. That’s where we can help. Our staff augmentation offering allows businesses to focus on what they do best, running their business. We can provide staffing for project managers, scrum masters, scrum coaches, devops engineers, and devsecops engineers, so you don’t have to.",
    par3: "Company A assist companies, from senior management to individual teams, to improve capabilities and deliver faster. This will allow you to  adapt quickly to changes, building on foundational concept of self-organizing teams and become a learning organization. We provide services to companies that are looking for answers related to Agile transformation and scaling. We can help your company navigate the complexities of business agility and unlock the power to faster delivery."
}

const about = (props) => (
    <div className="about-text-container container">
        <Row>
            <div className="col-12" data-aos="fade-up" data-aos-duration="2000">
                <h2>{aboutText.title}</h2>
                <p>{aboutText.par1}</p>
                <p>{aboutText.par2}</p>
                <p>{aboutText.par3}</p>
            </div>
        </Row>
    </div>
)

export default about