import React, { useEffect, useContext } from 'react';
import './style.scss';
import { Container } from 'react-bootstrap';
import TeamMember from '../../atoms/TeamMember';
import { StoreContext } from "../../../context/StoreContext";

const teamMemberLeft = {
    containerClass: "employee-container",
    infoClass: "info",
}

const Team = (props) => {
  const { state, actions } = useContext(StoreContext)
  const { employes } = state;
  const getEmployes = actions['employes'];

  useEffect(() => {
    getEmployes.getEmployes()
  }, [])
  const fadeIn = ['left', 'right']
  return (
    <Container className="team-container">
      {employes.status === 'error' && <i className="error-message">{employes.error.message}</i>}
      {employes.employes && employes.employes.map((employee, index) => (
          <TeamMember 
              {...teamMemberLeft}
              key={employee.id}
              name={employee.name}
              test={false}
              imgCode={employee.image}
              position={employee.position} 
              descriptionPar1={employee.descriptionPar1}
              descriptionPar2={employee.descriptionPar2}
              descriptionPar3={employee.descriptionPar3}
              descriptionPar4={employee.descriptionPar4}
              descriptionPar5={employee.descriptionPar5}
              fadeIn={fadeIn[index%2]}
          />
      ))}
      {employes.status === 'pending' && <i>Loading</i> }
    </Container>
  )
}

export default Team;