import React from 'react';
import InfoPeekAbout from '../../components/widgets/InfoPeekAbout';
import About from '../../components/widgets/About';
import AboutBanner from '../../components/widgets/AboutBanner';
import TransformBusiness from '../../components/widgets/TransformBusiness';
import Testimonial from '../../components/widgets/Testimonial';

const aboutUs = (props) => (
  <div>
    <AboutBanner />
    <TransformBusiness />
    <About />
    <Testimonial />
    <InfoPeekAbout />
  </div>
)

export default aboutUs;
