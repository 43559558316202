import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function Card(props){
  const { 
    cardWrapperClass, 
    cardClass,
    content, 
    contentClass, 
    fontAweAnimation, 
    fontAweDuration, 
    icon, 
    textAnimation, 
    textDuration,
    title, 
    titleClass,
    backgroundClass
  } = props
  return (
      <div className={`${cardWrapperClass} ${backgroundClass ? backgroundClass:''}`}>
          <FontAwesomeIcon icon={icon} data-aos={fontAweAnimation} data-aos-duration={fontAweDuration} />
          <div className={cardClass}>
            <h2 className={titleClass} data-aos={textAnimation} data-aos-duration={textDuration}>{title}</h2>
            <p className={contentClass} data-aos={textAnimation} data-aos-duration={textDuration}>{content}</p>
          </div>
      </div>
  )
}
