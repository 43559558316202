import React from 'react'
import OpenPositions from '../../components/widgets/OpenPositions';
import CareersBanner from '../../components/widgets/CareersBanner';
import CareersSidebar from '../../components/widgets/CareersSidebar';
import { Container, Row, Col } from 'react-bootstrap';
import './style.scss';


const careers = (props) => (
  <div>
    <CareersBanner />
    <div className="careers-content">
      <Container>
        <Row>
          <Col xs={12} lg={12}>
            <CareersSidebar />
          </Col>
          <Col xs={12} lg={12}>
            <p className="open-positions-heading">Open positions</p>
            <OpenPositions />
          </Col>
        </Row>
      </Container>
    </div>
  </div>
)

export default careers;
