import React from 'react';
import './style.scss';
import { Container, Row, Col } from 'react-bootstrap';


const teamBanner = (props) => (
    <div className="team-banner-container font-white">
        <Container className="team-banner-content-container">
            <Row  data-aos="fade-up" data-aos-duration="2000">
                <Col className="team-banner-heading-container">
                    <h1>The A Team</h1>
                </Col>
            </Row>
            <Row className="team-banner-common-things-heading"  data-aos="fade-up" data-aos-duration="1500">
                <Col>
                    <h2>All Of our Associates Have These 3 Things in Common.</h2>
                </Col>
            </Row>
            <Row  className="team-banner-common-things-container"  data-aos="fade-up" data-aos-duration="2000">
                <Col className="common-thing" xs={12} md={4}>
                        <h3>Expertise</h3>
                        <p>All of our associates bring expertise to the firm and clients we serve. Company A associates come with many years of experience in the discipline they serve.  They are problem solvers by nature, passionate about their work, and love helping our clients meet and achieve their goals.</p>                </Col>
                <Col className="common-thing" xs={12} md={4}>
                        <h3>Adaptability</h3>
                        <p>In the world of business, things can change at the drop of a hat. As experienced professionals, it is our job to adapt and remain flexible on a daily basis to better serve our clients. Our associates are experts in acclimating to various environments, managing stakeholders, and finding solutions.</p>
                </Col>
                <Col className="common-thing" xs={12} md={4}>
                        <h3>Leadership</h3>
                        <p>Leadership can come in all forms and is expected from each of our associates.  Our experienced Professionals effectively lead by providing the feedback that needs to be heard, as opposed to what one wants to hear. All of our associates possess the innate desire to support and develop those around them.</p>
                </Col>
            </Row>
        </Container>
    </div>
)

export default teamBanner;