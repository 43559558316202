import React from 'react'
import YouTubeBackground from '../../components/atoms/YouTubeBackground';
import ContactContent from '../../components/widgets/ContactContent';
import './style.scss';

const VIDEO_URL = "https://www.wearecompanya.com/static/videos/office.mp4";

const contactUs = (props) => (
  <div className="contact-container">
    <YouTubeBackground videoURL={VIDEO_URL} />
    <ContactContent />
  </div>
)

export default contactUs;