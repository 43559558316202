import React from 'react';
import './style.scss';

const Testimonial = () => {
    return (
        <div className="bg-color-red font-white testimonial-container font-italic">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <div className="center-testimonial">
                            <p className="testimonial-body">"BuyaToyota used to be our worst performing application. Ever since Milan and team took over we have received very few calls from the TDAs. They built a very flexible system that can do practically anything in the tier 2 space."</p>
                            <p className="font-weight-bold text-right m-0">Digital Marketing Manager</p>
                            <p className="font-weight-bold text-right m-0">Toyota Motor Sales</p>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <div className="center-testimonial">
                            <p className="testimonial-body">"When you work with Company A you are assured to get access to a variety of professionals who make it their goal to elevate your digital footprint. They understand the nuances of each business and the importance of finding solutions that make sense for specific verticals mixed with best practices. In the B2B insurance space, the Company A team took the time to understand our customer, how they worked, and then – and only then – did they provide a recommendation. I’d recommend Company A to any business looking for digital partners to come in and share a different approach to meeting their digital goals"</p>
                            <p className="font-weight-bold text-right m-0">Polly Neves</p>
                            <p className="font-weight-bold text-right m-0">Vice President, Marketing</p>
                            <p className="font-weight-bold text-right m-0">The Word & Brown Companies</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonial
