import React from 'react';
import InfoPeek from '../../atoms/InfoPeek';
import './style.scss';

let infoPeekAbout = {
    infoPeekTitle: "Join our team",
    infoPeekContent: "We're made up of a team of incredibly talented individuals and we're always growing. See what positions we have open and apply!",
    infoPeekWrapper: "info-peek-about",
    buttonText: "Open positions",
    infoPeekTitleClass: "info-peek-about-title",
    infoPeekContentClass: "info-peek-about-content",
    link: "/careers", 
    textAnimation: "fade-up",
    textAniDuration: "2000",
    buttonAnimation: "fade-left",
    buttonAniDuration: "2000"
};

const InfoPeekAbout = (props) => (
  <div className="info-peek-about-wrapper font-white">
    <InfoPeek {...infoPeekAbout} />
  </div>
)

export default InfoPeekAbout;
