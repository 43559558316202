import React from 'react';
import { Container } from 'react-bootstrap';
import './style.scss';

const TransformBusiness = (props) => {
    return (
        <Container  className="text-left transform-container">
            <p className="transform-heading">How We Can Help You</p>
            <p>Gone are the days of working in silos and delivering big presentations to a client with promises of results months or even years down the road. The current systems of development leave clients wanting. In the technology delivery industry, companies promise to deliver a particular product on a particular date. What no one realized, was the inherent risk everyone came to accept... the inevitable compromise that projects would face, to keep up with a business that refused to remain static while said projects were being completed.</p>
            <p>The future is unpredictable and planning far ahead is just untenable. Change is inevitable. For businesses, strategies change, priorities change, expectations change. Agile businesses can respond to changes quickly and adapt accordingly. This is the key to our mission:</p>
            <h4>To transform businesses by introducing them to great potential that today's cutting-edge technologies offer.</h4>
            <p>New platforms and processes allow us to shift to a new operational paradigm. We focus on results, not accomplishments.</p>
            <p>You don't just want a website, you want a website that produces lead generation, higher google rankings and satisfied customers needs. The nature of these solutions require communication with every layer of your organization. We believe in a client-centered approach. This means that the final product will always be an interpretation of two worlds, our development staff and client stakeholders. Our experience has proven time and again that clients cannot be patrons. They must be equal stakeholders in delivery. We are not looking for contracts. We are looking for partners.</p>
            <p>That is what makes us different. We are experienced in managing change. We are experienced in navigating unpredictable futures. We are experienced in environments with changing priorities. We are experienced agents of change.</p>
            <p>Come work with us!</p>
        </Container>
    )
}

export default TransformBusiness;
