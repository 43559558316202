import React, { createContext, useReducer } from "react";
import { reducer, initialState } from "./reducers";
import { useActions as getPosition } from './actions/getPositions'
import { useActions as getEmployes } from './actions/getEmployes'

const StoreContext = createContext(initialState);

const StoreProvider = ({ children }) => {
  // Set up reducer with useReducer and our defined reducer, initialState from reducers.js
  const [state, dispatch] = useReducer(reducer, initialState);

  // Create an object of all our actions, handling special cases where a simple dispatch is too primitive
  const actions = {
    positions: getPosition(state, dispatch),
    employes: getEmployes(state, dispatch)
  }

  return (
    <StoreContext.Provider value={{ state, dispatch, actions }}>
      {children}
    </StoreContext.Provider>
  );
};

export { StoreContext, StoreProvider };